<template>
  <div>
    <div class="room-body-header d-f ai-c jc-sb">
      <span class="d-f ai-c jc-sb">
        <common-timer v-model="dates" @change="search"></common-timer>
        <!-- <el-date-picker
          v-model="dates"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="search"
        >
        </el-date-picker> -->
        <el-time-picker style="margin-left: 5px" is-range v-model="times" range-separator="至" start-placeholder="开始时间"
          end-placeholder="结束时间" placeholder="选择时间范围" @change="search" value-format="HH:mm" format="HH:mm">
        </el-time-picker>
        <el-select v-model="identifyFlag" placeholder="请选择" clearable style="margin: 0 5px; width: 100px">
          <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <my-input placeholder="姓名、房号、身份证" v-model="searchQuery" clearable style="width: 200px; margin-right: 8px"
          @clear="search" />
        <el-button type="primary" @click="search"> 搜 索</el-button>
        <el-button v-if="!isMM" title="导出" @click="exportData(false)" :style="
          !(sectionItem && sectionItem.length)
            ? 'cursor:not-allowed;opacity:.5;margin-left:16px'
            : 'cursor:pointer;margin-left:16px'
        " :disabled="!(sectionItem && sectionItem.length)">
          保存
          <!-- <img class="icon-btn" src="@/assets/daochu.svg" alt="" srcset="" /> -->
        </el-button>
      </span>
      <!-- <el-button
        v-if="!isMM"
        :disabled="!(sectionItem && sectionItem.length)"
        @click="exportData"
        type="primary"
        >导出</el-button
      > -->
      <span>
        <common-daochu v-if="!isMM" title="全部导出" @click="exportData(true)" type="text" class="icon-btn-box">
          <img style="" class="c-p icon-btn icon-btn-all" src="@/assets/daochuquanbu.svg" alt="" srcset="" />
        </common-daochu>
      </span>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("unlock");
import dayjs from "dayjs";
const subDay = dayjs().subtract(6, "month");
export default {
  name: "unlock-body-header",
  data() {
    return {
      searchQuery: "",
      dates: [
        // (subDay.unix() > dayjs(`${new Date().getFullYear()}-01-01`).unix() ? subDay : dayjs(`${new Date().getFullYear()}-01-01`)).format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ],
      times: [
        new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00"),
        new Date(dayjs().format("YYYY-MM-DD") + " 23:59:59"),
      ],
      identifyFlag: "",
      roleList: [
        { id: 0, name: "宿管" },
        { id: 1, name: "学生" },
      ],
      timer: null
    };
  },
  methods: {
    ...mapActions(["getTableList", "batAdd", "saveRoomInfo", "getExcel"]),
    search() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        const params = {
          identifyFlag: this.identifyFlag,
          pageNum: 1,
          complexCondition: this.searchQuery,
        };
        if (this.dates && this.dates.length == 2) {
          params.startDate = this.dates[0]
            ? dayjs(this.dates[0]).format("YYYY-MM-DD")
            : "";
          params.endDate = this.dates[1]
            ? dayjs(this.dates[1]).format("YYYY-MM-DD")
            : "";
        } else {
          params.startDate = "";
          params.endDate = "";
        }
        if (this.times && this.times.length == 2) {
          params.startTime = typeof this.times[0] === 'string' ? this.times[0] : dayjs(this.times[0]).format("HH:mm:ss");
          params.endTime = typeof this.times[1] === 'string' ? this.times[1] : dayjs(this.times[1]).format("HH:mm:ss");
        } else {
          params.startTime = "";
          params.endTime = "";
        }
        this.getTableList(params);
      }, 100);
    },
    sure() {
      // 批量添加确认
      const { build, cell, room } = this.batchAdd;
      if (build && cell && room) {
        this.batchAdd(this.batchAdd);
      }
    },
    exportData(all = false) {
      this.getExcel(
        all
          ? 0
          : {
            exportIds: this.sectionItem.map((it) => it.id).join(","),
          }
      );
    },
    addRoomFunc(data) {
      this.saveRoomInfo(data).then(() => {
        this.drawer = false;
      });
    },
  },
  watch: {
    visible() {
      this.batchAdd = {
        build: "", // 楼栋
        cell: "", //  楼层数量
        room: "", // 房间
      };
    },
  },
  computed: {
    ...mapState(["sectionItem"]),
    isMM() {
      return this.$store.state.login.isMM;
    },
  },
};
</script>

<style lang="scss" scoped>
.room-body-header {
  height: 60px;
  background: #fff;
  padding: 0 24px;
}

.room-header-add {
  .el-input {
    margin-bottom: 12px;
    width: 100%;
  }

  button {
    margin: 0 4px;
    width: 88px;
  }
}
</style>