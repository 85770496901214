<template>
  <div class="house-master h-100_">
    <div class="room-body h-100_ no-side">
      <unlock-master-body-header />
      <div class="room-body-content w-100_">
        <common-table short :data="tableList" style="width: 100%" @selectionChange="handleSelectionChange"
          :setQuerySearchPagesize="setQuerySearchPagesize" :setQuerySearchPageNum="setQuerySearchPageNum"
          :querySearch="querySearch" :queryTableData="getTableList" :total="total" :indexWidth="4">
          <el-table-column type="selection" width="55" align="center">
          </el-table-column>
          <el-table-column label="序号" type="index" width="100">
            <template slot-scope="scope">
              {{
                (querySearch.pageNum - 1) * querySearch.pageSize +
                1 +
                scope.$index
              }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="phone" label="手机号"> </el-table-column>

          <el-table-column prop="identityNo" label="身份证号码">
          </el-table-column>
          <el-table-column prop="buildingName" label="楼栋"> </el-table-column>
          <el-table-column prop="roomNo" label="房号"> </el-table-column>
          <el-table-column prop="roomNo" label="身份">
            <template slot-scope="scope">
              {{ scope.row.identifyFlag == 1 ? "学生" : "宿管" }}
            </template>
          </el-table-column>
          <el-table-column prop="unlockingTime" label="开锁时间">
            <template slot-scope="scope">
              {{ dayjs(scope.row.unlockingTime).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
        </common-table>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } =
  createNamespacedHelpers("unlock");
import UnlockMasterBodyHeader from "./unlockMasterBodyHeader.vue";
export default {
  name: "house-master",
  components: {
    UnlockMasterBodyHeader,
  },
  data() {
    return {
      dayjs,
      showEdit: false,
      modifyVisible: false,
      modifyName: "",
      deleteVisible: false,
    };
  },
  methods: {
    ...mapActions(["getTableList"]),
    ...mapMutations([
      "setSectionItem",
      "setQuerySearch",
      "setQuerySearchPagesize",
      "setQuerySearchPageNum",
    ]),
    handleSelectionChange(val) {
      // 多选的item
      this.setSectionItem(val);
    },
    deleteFunc() {
      //   删除
      this.deleteVisible = false;
    },
    modify() {
      //  修改
      this.modifyVisible = false;
    },
    finishEdit(data) {
      //  编辑完成获取数据；
      console.log(data);
    },
    edit(row) {
      // 编辑当前数据
      if (this.chooseType == "cell") {
        //
        this.modifyName = row.cellNo;
        this.modifyVisible = true;
      } else {
        this.showEdit = true;
        console.log(row);
      }
    },
    deleteF(row) {
      // 删除当前数据
      if (this.chooseType == "cell") {
        //
        console.log(row);
        this.deleteVisible = true;
      } else {
        //
        this.$message.error("无法删除，请通过洛克侠app 绑定");
      }
    },
  },
  mounted() {
    this.setSectionItem(null);
  },
  computed: {
    ...mapState(["tableList", "chooseType", "total", "querySearch"]),
    cellsData() {
      console.log(this.chooseType == "cell");
      return this.chooseType == "cell";
    },
  },
};
</script>

<style lang="scss" scoped>
.house-master {
  background: #f4f5f6;
  padding: 24px;
}
</style>
